.collapsible-text {
    .collapse-toggler {
        margin-top: -1.2rem;
    }
}
.collapse-toggler{
    color: var(--link-color);
    padding-left: var(--padding);
    float: right;
    z-index: 100;
    position: relative;
    background-color: var(--bg-color);
}
.collapse-toggler.bottom {
    margin: 5px 0 5px 10px;
}

.collapse-toggler:hover:not(:has(.glyphicon)){
    cursor: pointer;
    text-decoration: underline;
}

.collapse-visible-part{
    margin: 0;
    word-wrap: break-word;
    display: -webkit-box;
}

.coll-text.collapse.in{
    display: inline;
}

.coll-text-tgl{
    color: #9e9e9e;
}

.coll-text-tgl:hover{
    cursor: pointer;
    text-decoration: underline;
}

.coll-text-tgl.glyphicon:hover{
    cursor: pointer;
    color: #4e4e4e;
    text-decoration: none;
}

.collapse-hidden-in {
    margin: 4px 0 0 0;
    border-radius: 2px;
}

.collapse li {
    word-break: break-all;
}

.collapsible-text {
    .with-overflow {
        transition: height 0.5s ease-in-out;
    }
    .expanded {
        display: inline-block;
    }
    .collapsed {
        position: relative;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .collapsed.with-overflow:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        background: linear-gradient(to bottom, transparent, var(--bg-color));
        height: 33%;
    }
}

.collapsible-scroller {
    /*height: 150px;*/
    overflow-y: auto;
    max-height: 150px;
}
