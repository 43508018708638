@font-face {
  font-family: "SourceSansPro-Regular";
  src: url("../fonts/SourceSansPro-Regular.ttf")  format("truetype");
}

html {
  --maxlw: 750px;
  --minlw: 400px;
  --svw: 400px;
  --lh: 1.4rem;
  --margin: 35px;
  line-height: var(--lh);
}

body{
  font-family: "SourceSansPro-Regular", Arial, Helvetica, sans-serif;
  font-size: 14px;
  overflow: hidden;
  scroll-behavior: auto;
}

/* FIXME don't know how to force full size on inner component other than spelling out all parents */
html,
body,
#root,
.app,
.page-content,
.search-results,
.grid-view {
  height: 100%;
  box-sizing: border-box;
}

.admin, .admin-toggle {
  /*border: 1px dashed !important;*/
  /*font-style: italic !important;*/
  background-image: linear-gradient(to bottom right, #FFFFFF00, #C8C8C888) !important;
  input, textarea {
    background-image: linear-gradient(to bottom right, #FFFFFF00, #C8C8C8C8) !important;
  }
  span {
    /*font-style: italic !important;*/
  }
}

.horizontal {
  display: flex;
  flex-direction: row;
}

.vertical {
  display: flex;
  flex-direction: column;
}

/* Disable until fully implemented */
button.admin.help {
  display: none;
}

button.glyphicon:hover:not(:disabled) {
  color: black;
}

.message {
  color: #00731E;
  white-space: pre-line;
}
.alert-warning .er-l-dlg-lbl-type, .message.warning {
  color: #cc8e00;
}
.alert-warning .er-l-dlg-lbl-type {
  /*background: #EEFF00;*/
}
.alert-error .er-l-dlg-lbl-type, .message.error {
  color: #BC2511;
}
.alert-error .er-l-dlg-lbl-type {
  font-weight: bold;
  /*background: #FFEEEE;*/
}
/* unused */
.message.user-not-found {
  color: #9A4C00;
}

.jsoneditor-react {
  display: flex;
  flex: 1;
}

.simple-select {
  line-height: 1rem;
  label {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    span {
      flex: auto;
      margin: auto 0.25rem auto auto;
    }
    .select__control {
      display: inline-flex;
      min-height: 1rem;
    }
    .select__value-container {
      padding: 0 4px 0 0;
    }
    .select__dropdown-indicator {
      padding: 0;
      margin: auto;
    }
    .select__single-value {
      min-width: 1rem;
      text-align: start;
    }
  }
}

.simple-select.disabled {
  pointer-events: none;
  .select__control {
    background-color: transparent;
    border-color: transparent;
  }
  .select__indicators {
    display: none;
  }
}

.input-reset {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.input-reset.disabled {
  visibility: hidden;
  cursor: default;
}

.input-reset:hover:not(.disabled) {
  color: red;
  font-weight: bold;
}

.input-reset.inside {
  margin-left: -1.5em;
  margin-right: 0.5em;
}

.markdown {
  .md-p {
    margin-bottom: 0.5rem;
  }
}

.markdown.error {
  color: #c9302c;
}

.markdown .interpolated div {
  display: inline;
}

.app {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  /*width: 50% !important;
  height: 400px !important;*/
}
.page-content {
  flex: 1;
  overflow: hidden;
}

.app-root {
  text-align: center;
}

.landing-page .app-container, .error-page, .maintenance-page {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.landing-page {
  height: 100%;
  margin: 0 var(--margin);
  overflow: hidden;
  .search-box .copy-to-clipboard {
    display: none;
  }
}

.navbar-logo-mini {
  display: none;
  height: 46px;
  text-align: start;
}

.login-form button.help {
  float: left;
  margin-left: 20px;
  margin-top: 20px;
  font-size: 1.5rem;
  border: none;
}

/* Tenant customization -- override in tenant.css */
.client-logo {
  /* ensure it's hidden if not defined */
  opacity: 0;

  /* Don't obscure any menus */
  z-index: 1;
  margin-left: var(--margin);
  text-align: end;
  display: block;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  /* default client logo size/position, tweak as needed in tenant.css */
  background-image: url('../img/tenant-logo');
  width: 150px;
  height: 48px;
}

/* Tenant customization -- override in tenant.css */
.landing-page .client-logo {
  /* don't obscure the plex logo */
  z-index: -1;
}
.login-form .client-logo, .help-form .client-logo {
  float: right;
  margin-top: -40px;
  /* Cf .login-fields */
  margin-right: 40px;
}

form.help {
  .help-body {
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    max-height: 80%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: var(--margin);
    .help-img-wrp {
      margin-top: var(--margin);
    }
    .client-logo {
      display: none;
    }
    button.link {
      position: relative;
      left: 0;
      z-index: 10;
      text-align: start;
    }
    .chat-session {
      height: unset;
      width: 100%;
      overflow: auto;
      flex: 1 1 auto;
    }
  }
}

form.help.embedded {
  .link {
    display: none;
  }
  .help-body {
    width: 100%;
    height: 100%;
    left: unset;
    transform: none;
  }
}

.error-page, .maintenance-page {
  text-align: center;
  font-size: 18px;
}

.error-page details {
}

.error-page details > * {
  font-family: monospace;
  text-align: left;
  margin: 0 200px;
}


.landing-page .logo-wrapper, .error-page .logo-wrapper {
  margin: 20px auto;
  width: 100%;
}

.app-container.chat {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--margin);
  .logo-wrapper {
    margin: 10px auto;
    .app-logo {
      height: 48px;
    }
  }
}

.modal-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.draggable .modal-title {
  cursor: move;
}

.modal-header, .modal-footer {
  padding: 0.5rem 0.75rem;
}

.modal-header button.btn-close {
  vertical-align: top;
  padding: 4px;
  margin: 4px;
  top: 4px;
  cursor: pointer;
}

.modal-header button.btn-close:hover {
  /* TODO: make this "bold" */
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e");
}

.modal-footer:has(.rnd-resizable-handle), .modal-footer .rnd-resizable-handle {
  padding: 0;
}

.grid-viewport-row .loading-animation {
  width: var(--maxlw);
  height: 4rem;
  padding-top: 1rem;
}

.ketcher-wrp-mdl{
  min-width: 900px;
  min-height: 728px;
  .modal-footer {
    flex-wrap: nowrap;
    column-gap: 4px;
    .btn {
      white-space: nowrap;
      width: initial;
      padding: inherit;
    }
  }
}

.ketcher-wrp-mdl .smiles-list {
  /*flex-grow: 1;*/
  width: 100%;
  text-align: left;
  max-height: 200px;
  overflow: auto;
}

.list-divider {
  color: #AEAEAE;
  padding: 0 5px;
}

/* Idea taken from unichem, e.g. https://www.ebi.ac.uk/unichem/compoundsources?type=sourceID&compound=chembl25&sourceID=1 */
.long-text, div.long-text, .long-list-element {
  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
  display: block;
}

/* Make the truncated text a bit narrower on this one */
.long-list-element {
  text-overflow-ellipsis: "...   ";
}

.long-text:hover, .long-list-element:hover {
  white-space: normal;
  overflow-wrap: break-word;
}

.smiles .long-text:hover {
  word-break: break-all;
}

/* Handle truncation of multi-line spans */
.truncate-overflow-clamp {
  --max-lines: 3;
  display: -webkit-box;
  -webkit-line-clamp: var(--max-lines);
  -webkit-box-orient: vertical;
}

.truncate-overflow {
  --max-lines: 3;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
}
.truncate-overflow:after {
  content: "";
  text-align: right;
  position: absolute;
  /*top: calc(var(--lh) * (var(--max-lines) - 1));*/
  right: 0;
  width: 10%;
  height: 1.2em;
  background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1) 50%);
  pointer-events: none;
}
.truncate-lines-2 {
  --max-lines: 2;
}
.app .react-tooltip-arrow {
  z-index: 1000;
  background-color: #DDD;
  color: black;
}

.app .react-tooltip {
  max-width: 400px;
  z-index: 1000;
  padding: 8px;
  background: #eaeaea;
  color: #545454;
  border-radius: 4px;
}
.app .react-tooltip ul {
  padding-left: 1em;
  margin-bottom: 4px;
}
.app .react-tooltip b {
  color: black;
}
.app .react-tooltip em {
  font-style: normal;
  /*text-decoration: underline;
  background: white;*/
}

.tooltip-text {
  opacity: 0;
  transition: opacity 0.01s;
  visibility: hidden;
  background: #eaeaea;
  padding: 8px 24px;
  position: absolute;
  contain: paint;
  z-index: 5;
  width: 400px;
  max-width: 400px;
  top: 50%;
  left: 105%;
}
/* *:has(> .tooltip-text) */
.tooltip-host {
  position: relative;
  display: inline-block;
}
*:hover > .tooltip-text {
  opacity: 1;
  visibility: visible;
}
.tooltip-text b {
  color: black;
}
ul.tooltip-text {
  list-style-type: disc;
}
ul.tooltip-text.activities {
  margin-bottom: auto;
  padding-bottom: 1rem;
  overflow-y: visible;
  max-height: none;
}

.message-list .modal-header {
  padding: 8px 12px;
}

.message-list .modal-title span {
  flex-grow: 1;
}

.message-list .modal-body {
  padding: 0;
}
.message-list .modal-body > div {
  padding: 8px 12px;
}

.message-details-list {
  width: 100%;
}

.message-details-list .message-details {
  list-style: none;
  padding: 0 0 0 20px;
  max-height: 100px;
  overflow: auto;
  margin-right: 20px;
}

.search-results {
  margin-left: 24px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: hidden;
}

.query-summary {
  flex: initial;
  /* Needs to be above scrolling grid to receive events */
  z-index: 1;
  margin-left: var(--margin);
}

.search-results.search-results-loading {
  flex-wrap: nowrap;
  width: 100%;
}

.search-results-loading .grid-loading {
  flex: 1 1 auto;
}

.search-results-loading .query-summary {
  flex: 0 0;
  position: relative;
  .query-summary-contents {
    position: relative;
    margin-right: 35px;
  }
}


.graph-results {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: var(--margin);
  padding-bottom: var(--margin);
  .loading-animation {
    display: flex;
    flex-direction: column;
  }
  label:has(.category-select) {
    /*flex: 1 1;*/
    display: flex;
  }
  .category-select {
    display: inline-block;
    flex: 1 0 auto;
    margin-left: var(--margin);
    .category-select__control {
      min-height: fit-content;
    }
    .category-select__menu {
      width: fit-content;
    }
    .category-select__value-container, .category-select__input-container {
      padding: 0;
      margin: 0;
    }
    .category-select__indicators, .category-select__indicator {
      padding: 0;
    }
  }
}

.graph-controls {
  z-index: 1;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--padding);
  label {
    white-space: nowrap;
  }
}

.evidence-arrow {
  position: absolute;
  padding-top: 0.07rem;
  float: left;
  top: 96px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 20%;
  height: 0.2rem;
  background-color: black;
}

.evidence-arrow:after {
  float: right;
  transform: translateX(50%) translateY(-50%);
  content: "\e072";
  display: inline-block;
  font-family: 'Glyphicons Halflings', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}


.graph-details {
  /*border: 1px solid green;*/
  position: relative;
  max-width: 50%;
  flex: 1 1;
  margin-left: var(--margin);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
.graph-details .nodes {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  .labeled-entity {
    margin: var(--margin);
    flex: auto;
    max-width: 50%;
    .ball-lg div {
      height: 128px;
      width: 128px;
    }
    img {
      max-height: 128px;
      width: 100%;
    }
  }
}

.react-sigma {
  /*border: 1px solid red;*/
}

.selection-rectangle {
  display: none;
  border: 1px dashed gray;
  position: absolute;
  z-index: 1000;
  /* TODO: translucent background color */
}

.graph-details .edges {
  /*border: 1px solid red;*/
  margin: var(--margin);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 1;
  overflow: hidden;
}

.graph-details .dataset-edges {
  align-self: center;
  min-height: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  overflow: hidden;
  margin-top: var(--margin);
}

.graph-details .dataset-edges .dataset {
  flex: 0 0 auto;
  font-weight: bold;
  font-size: 1rem;
  text-decoration: none;
  background-color: #CECECE;
}

.graph-details .dataset-edges .edge-details {
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .edge-detail {
    margin-left: var(--margin);
  }
}

.graph-view {
  height: 100%;
  /* Container is required by react-sigma to have a non-empty size */
  .graph-container {
    width: 100%;
    height: 100%;
  }
}

.graph-results .graph-loading-animation {
  width: 50%;
  height: 50%;
  .graph-container {
    width: 100%;
    height: 100%;
    margin: auto;
  }
}

.center-vertical {
  margin: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.center-horizontal {
  width: 100%;
  margin: auto;
}

.grid-filter {
  flex: initial;
  height: fit-content;
  /* needs to be above grid so results can scroll under it */
  z-index: 1;
  display: flex;
  background-color: var(--bg-color);
  width: var(--maxlw);
  min-width: var(--minlw);
  max-width: var(--maxlw);
}
.grid-loading {
  max-width: var(--maxlw);
  height: 100%;
}

.grid-loading .loading-animation {
  position: relative;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  height: 75%;
  width: 100%;
}

.query-summary-contents {
  position: absolute;
  width: var(--svw);
  margin-right: var(--margin);
}
.grid-view {
  height: 100%;
  width: 100%;
}
.grid-viewport {
  /* TODO: derive these from results filter actual height */
  margin-top: -73px;
  padding-top: 73px;
  overflow-y: auto;
  contain: strict;
  height: 100%;
}
.grid-virtualized {
  width: 100%;
  position: relative;
  overflow-x: clip;
}
.grid-virtualized-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.grid-viewport-row {
  min-width: var(--minlw);
  max-width: var(--maxlw);
  /* Don't clip Y or menus get clipped */
  overflow-x: clip;
}
.empty-result {
  height: 100%;
  min-width: var(--minlw);
  max-width: var(--maxlw);
  padding-bottom: var(--margin);
}

.empty-result-content {
  border: 1px solid #ebebeb;
  text-align: center;
  font-size: 21px;
  background-color: #fafafa;
  height: 100%;
  padding-top: 20%;
}

.row-loading {
  color: red;
  display: none;
}
.row-scrolling {
  color: green;
  height: 0;
}
.hidden {
  visibility: hidden;
}
.as-top {
  color: inherit;
}
.upper {
  text-transform: uppercase;
}
.category {
  color: #a1a1a1;
  text-transform: uppercase;
  display: block;
}

/* vs :placeholder-shown */
::placeholder {
  font-style: italic;
}
.star-rating {
  color: #363636;
}
.star-rating.checked {
  color: orange;
}
.out-of-bounds {
  color: red;
}

.markdown.alert {
  padding: 0.2rem;
}

.jsoneditor-react .jse-contents {
  max-height: 35%;
}

.file-uploader {
  .attach-button {
    margin: 4px 2px;
    width: 1.5rem;
    height: 1.5rem;
  }
  .attach-button.read-only {
    border: transparent;
    background: none;
    box-shadow: none;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /*row-gap: 0.25rem;*/
  .attached-file {
    margin: 4px 2px;
    display: inline-block;
    padding: 2px;
    border: 1px solid gray;
    border-radius: 4px;
    .circular-progress {
      margin-left: 4px;
      display: inline-block;
      width: 12px;
      height: 12px;
    }
    .delete-button {
      cursor: pointer;
      padding-right: 1rem;
    }
  }
}

.ai-modal {
  max-height: 95%;
  min-height: 30%;
  flex-direction: column;
  .waiting {
    font-style: italic;
  }
  .prompt {
    min-height: 2rem;
  }
  .prompt, .loading-animation {
    margin-right: 8px;
    flex-grow: 1;
    width: unset;
    /*min-height: 3rem;*/
  }
  label.context > b {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid gray;
    button {
      background: none;
      border: none;
    }
  }
  label.context, p > label {
    color: black;
    font-weight: bold;
    width: 100%;
    > span {
      color: var(--dropdown-color);
      display: inline-block;
      max-height: 150px;
      overflow-y: auto;
    }
  }
  .assistant, .search-analyst {
    .alert {
      padding: 0.1rem;
    }
  }
  .alert {
    padding: 0.5rem;
  }
  .ctm__checkbox {
    margin-top: var(--padding);
  }
  .model-settings {
    margin-top: var(--padding);
    width: 100%;
    .simple-select {
      vertical-align: baseline;
      label, .select__control {
        display: inline-flex;
      }
    }
    label, div {
      display: inline-block;
      .react-numeric-input {
        margin: 0 var(--margin);
        max-width: 4rem;
        input {
          max-width: 100%;
        }
      }
    }
  }
}

.ai-modal.modal-dialog {
  max-width: 65%;
}

.ai-modal .modal-header {
  padding: 4px;
  align-items: flex-start;
}

.ai-modal .modal-title {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
}

.ai-modal .modal-title img {
  top: 50%;
  margin: auto 8px;
}

.ai-modal .alert-warning {
  border: 1px solid darkorange;
  padding: 4px;
  margin: 0 0 8px 0;
  flex-grow: 1;
}
.ai-modal .alert-warning h6 {
  color: black;
  font-weight: bolder;
}

.ai-modal .logo {
  height: 18px;
  margin-left: 8px;
}

.ai-modal .title {
  flex-grow: 1;
}

.ai-modal .user-input {
  position: relative;
  display: flex;
  flex-direction: row;
}

.ai-modal .categories-selection .dropdown-menu.show {
    columns: 2;
}

.ai-modal .buttons {
  display: none;
  justify-content: space-evenly;
  align-items: center;
  margin: 8px 0;
}

.ai-modal .buttons .glyphicon {
  padding: 4px;
  font-size: 19px;
}

.ai-modal .dropdown-toggle:after {
  display: none;
}

.ai-modal .categories-selection .btn {
  margin-left: 4px;
  padding: 0;
  width: 22px;
  height: 22px;
  .glyphicon {
    font-size: 90%;
    display: inline-block;
    margin: 0;
    padding: 2px;
  }
}

.ai-modal .buttons > span {
  white-space: nowrap;
  flex-grow: 1;
  margin: 0 8px;
  text-align: right;
}

.ai-modal .conversation {
  max-height: 400px;
  margin-bottom: var(--margin);
}

.ai-badge {

}

.ai-modal .user-input select {
  max-width: 175px;
  text-overflow: ellipsis;
}

.ai-modal span.system {
  font-weight: bold;
  color: inherit;
}

.chat-session {
  margin: auto;
  padding-bottom: var(--padding);
}

.conversation {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 0 1 auto;
  ul, ol {
    margin-top: var(--margin);
  }
  div, a {
    margin-bottom: 4px;
  }
  .thinking {
    opacity: 50%;
  }
  .llm-training, .thinking {
    font-style: italic;
  }
  .truncated {
    font-style: italic;
    color: red;
  }
  .timestamp {
    font-family: monospace;
    font-size: 0.75rem;
    margin-right: 0.25rem;
  }
  .avatar {
    display: inline-block;
    color: white;
    background-color: black;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    margin: auto var(--margin) auto auto;
    text-align: center;
    vertical-align: baseline;
  }
  .glyphicon:not(.search-icon) {
    float: right;
    margin-left: var(--margin);
  }
  .glyphicon:not(.search-icon):hover {
    color: black;
  }
  .search-link {
  }
  .turn {
    width: 100%;
    overflow-x: hidden;
  }
  .turn:not(:hover) {
    .glyphicon {
      visibility: hidden;
    }
  }
  .user, .assistant, .search-analyst, .system, .tool {
    display: block;
    border-radius: 4px;
    padding: var(--padding);
    margin: 0 0 var(--margin) 0;
  }
  .system {
    color: black;
  }
  .user:not(.alert) {
    color: #5858ab;
    background: rgba(6, 6, 52, 0.18);
  }
  .user {
    display: inline-block;
  }
  .assistant, .search-analyst {
    color: #333;
    background-color: #eaf5fc;
  }
  .tool-result-text {
    margin-right: var(--margin);
  }
  .tool-use, .tool-result {
    opacity: 75%;
  }
  .tool-use-text {
    margin-left: 16px;
  }
  .tool-use:not(.alert) {
    color: #33333396;
  }
  .tool-result:not(.alert) {
    /* Same colors as user, but fainter */
    color: #5858ab96;
    background-color: rgba(6, 6, 52, 0.08);
    max-height: 8rem;
    overflow: hidden auto;
  }

  /* Encompasses all *-agent turns */
  .agent, .search-analyst {
    color: #333;
    background-color: #c8e5bc;
    opacity: 75%;
    max-height: 10rem;
    overflow: auto;
    /*margin-left: 16px;*/
  }
  .search-analyst.tool-result:not(.alert) {
    /*margin-left: 16px;*/
    background-color: #c8e5bc;
  }
  .search-analyst-result:not(.alert) {
    background-color: #d7e4d0;
  }

  button.rerun {
    width: 24px;
    height: 24px;
    border: none;
  }
}

.ai-modal .modal-title {
  flex-direction: row;
  width: 100%;
}

.attachments {
  display: flex;
  flex-direction: row;
  .simple-select {
    margin: 5px;

  }
}

.ai-templates {
  border-top: 1px solid gray;
  textarea {
    min-height: 5rem;
    width: 100%;
  }
}

.flat-button, .flat-button:before {
  cursor: pointer;
  background: transparent;
  background-image: none;
  border: none;
  padding: 0;
}
.flat-button:hover:not(:disabled), .flat-button:before:hover:not(:disabled) {
  /*background: buttonface;*/
}

.modal-title > div {
  flex-grow: 1;
}

.modal-title .copy-to-clipboard {
  margin: 4px 4px 4px auto;
  text-align: end;
}

.modal-title .glyphicon {
  padding: var(--padding);
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(0.65);
  }
  to {
    transform: scale(1);
  }
}

.icon-pulse, .export-icon-pulse {
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.slide-generator {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  .two-columns {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    > textarea {
      height: 8rem;
      width: 50%;
    }
  }
  button {
    flex: 0 0 auto;
    color: black;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  .slides-markdown {
    border: 1px solid gray;
    max-height: 100px;
    overflow-y: auto;
    textarea {
      min-height: 100px;
      width: 100%;
    }
  }
  .slideshow {
    flex: 1 1 auto;
    .reveal {
      width: 100%;
      height: 100%;
      .slides {
      }
    }
  }
}

div.authorizing-placeholder {
  position: relative;
  top: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  height: fit-content;
  margin: auto;
  transform: translateY(-25%);
}

.template-sandbox .download-ppt {
  width: 32px;
  height: 32px;
}

.download-ppt {
  background-image: url("../img/ppt.png");
  background-size: auto 100%;
  opacity: 0.60;
}

.download-ppt:hover {
  opacity: 1;
}

div.authorizing-placeholder .loading-animation {
  margin-top: 2rem;
}

.authorizing-placeholder > * {
  position: inherit;
  margin: auto;
  width: auto;
  transform: none;
}

.hidden {
  display: none !important;
}

@media all and (max-width: 1264px) {
  .query-summary { display: none; }
  .search-results-loading .query-summary { display: initial }
  .client-logo {flex: auto; }
  .navbar-collapse {
    flex-wrap: wrap-reverse;
    row-gap: var(--gap);
  }
}

@media all and (max-width: 900px){
  .navbar-logo, .navbar-brand { display: none; }
  .navbar-logo-mini { display: inline-block; }
  .navbar-menus:has(.navbar-logo-mini) { flex: auto }
}

/* Browser-specific hacks */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS here */
  html {
    line-height: 1.4rem;
  }
  .query-summary {
    margin-left: 35px;
  }
  .grid-filter {
    margin-left: 35px;
    width: 750px;
    min-width: 400px;
    max-width: 750px;
  }
  .query-summary-contents {
    margin-right: 35px;
    width: 400px;
  }
  .grid-viewport-row {
    min-width: 400px;
    max-width: 750px;
  }
  .empty-result {
    min-width: 400px;
    max-width: 750px;
    padding-bottom: 35px;
  }
  .truncate-overflow-clamp {
    display: -webkit-box;
    /*-webkit-line-clamp: 3;*/
  }
  .truncate-overflow {
    max-height: 4.2rem;
    overflow: hidden;
  }
  .truncate-lines-2 {
    max-height: 2.8rem;
  }
}
