.result-stub {
  text-align: center;
  padding: 42% 5px !important;
  height: 400px;
}

div:has(.sandbox), .sandbox {
  height: 100%;
  overflow: hidden;
}

.template-sandbox {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  .tab-content {
    flex-grow: 1;
    overflow: hidden;
    margin-bottom: 1rem;
    .tab-pane {
      height: 100%;
    }
  }
}

.rendered {
  display: flex;
  flex-direction: column;
}
.result-wrapper {
  flex-grow: 1;
  border-radius: 3px;
  border: 1px solid #e4e4e4;
}
.result-default {
  border-color: orange;
}
.result-prepared {
  border-color: green;
}

.result-output {
  padding: 10px !important;
  word-break: break-word;
}

.clear-margin {
  margin: 0 !important;
}

.float-right {
  float: right;
}

.error-message {
  color: red;
}

.example-title {
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
}

.dropup, .input-element {
  margin-right: 5px;
}

.sandbox button.copy-to-clipboard {
  margin-left: var(--margin);
  line-height: inherit;
  top: initial;
}

.sandbox {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  overflow: hidden;
  > :first-child {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    > div {
      max-height: 100%;
      overflow: hidden;
    }
    .rendered {
      overflow-y: auto;
    }
  }
  .rendered {
    display: flex;
    flex-direction: column;
  }
  .template-editor, .template-modified {
    min-height: 150px;
    max-height: 45%;
    height: 415px;
  }
  .cm-scroller, textarea.template-editor, textarea.template-modified {
    overflow-y: auto;
  }
}

div:has(> .template-editor) {
  max-height: 100%;
  /*overflow: hidden;*/
}

.sandbox-tab {
  padding-top: 1rem;
  border-left: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
  margin-bottom: 1rem;
}

.footer {
  margin: 15px 5px;
  border-top: 1px solid #d0d0d0;
  padding-top: 20px;
}
