#dropdown-export-menu button[aria-expanded="false"]:focus {
    background-color: #fff;
    border-color: #cccccc;
    color: #737373;
}

.dropdown-menu .dropdown-item > *:not(.fi) {
  font-size: 1rem;
  padding: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.dropdown-menu .dropdown-item {
  img, .fi {
    flex: initial;
    width: 36px;
    height: 36px;
  }
  .fi {
    padding: 4px 0 0 4px;
  }
}

.dropdown-menu .dropdown-item button {
  text-overflow: ellipsis;
}

.dropdown-item select {
  margin-left: 0.25rem;
}

.dropdown-item .glyphicon-copy {
  /*margin-left: -16px;*/
  padding-right: 4px;
}

.dropdown-item.api-key {
  :before {
    margin-right: var(--margin);
  }
  display: inline-block;
}

.dropdown-item.numeric {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  column-gap: 0.1rem;
}
.dropdown-item.numeric div {
  flex: 1;
  padding: 0;
}
.dropdown-menu .react-numeric-input {
  flex: 0;
  width: 4rem;
}
.dropdown-menu .react-numeric-input input {
  width: 4rem;
  text-align: right;
}

.settings-menu .cpd-similarity {
  display: flex;
  flex-direction: row;
}
.settings-menu select:disabled {
  border-color: transparent;
}
