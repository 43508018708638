.search-box {
    display: flex;
    column-gap: var(--gap);
    flex: auto;
    .copy-to-clipboard {
        top: 0;
    }
}

.landing-page .landing-page-inputs {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    row-gap: var(--padding);
    width: 90%;
    max-width: 900px;
    margin: var(--margin) auto;
    padding: 0 var(--padding);
    > div.or {
        text-align: center;
    }
    > div {
        margin: auto;
        width: 100%;
    }
    .search-box {
        flex: 0 0 auto;
        max-height: initial;
        margin: 0;
    }
    .search-box, .chat-session {
        margin-top: 0;
    }
}

.search-hint{
    color: #949494;
    margin: 72px 0 0 88px;
}

.as-option {
    cursor: pointer;
    padding: 8px 10px;
    border-bottom: 1px solid #eaeaea;
    min-height: 50px;
}
.as-option:last-child {
    border-bottom: none;
}
.as-option.focused {
    background-color: var(--highlight-color);
}
.as-option.focused:has(button) {
    /*background-color: inherit;*/
}
.as-option.focused button {
    font-weight: bold;
    color: var(--active-color);
}

/* DO NOT apply flex to markdown elements, or any undecorated text will have display: block */
.as-option {
    .as-list:has(img), .as-top:has(img) {
        display: flex;
        flex-direction: row;
    }
}

.as-option br.markdown {
    content: "";
    font-size: 0;
}

strong.markdown {
    font-weight: bold;
}

.as-top-label {
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
    display: inline-block;
    flex: 1;
    > div {
        display: inline;
    }
}

.as-list-image {
    margin-right: 10px;
}

.as-top-image {
    flex-basis: fit-content;
    margin-right: 15px;
    position: relative;
    right: 0;
}

.is-disabled > .as-option {
    border-bottom: none;
}

.chat-session {
    height: 100%;
    display: flex;
    flex-direction: column;
    .controls {
        flex: 0 0;
        flex-direction: row;
        flex-wrap: nowrap;
        display: flex;
        margin-bottom: var(--margin);
        .loading-animation {
            flex: 1 1 auto;
            margin: var(--margin) auto;
            .sliding-dot {
                width: 10px;
                height: 10px;
            }

        }
        div.glyphicon, button.glyphicon {
            flex: 0 0 auto;
            min-width: 24px;
            width: 24px;
            height: 24px;
            border: none;
        }
        button.glyphicon-trash {
            margin-right: 2rem;
        }
    }
    /* hacky button inside full bounds of 'chat-box-input' w/o affecting border placement */
    .chat-box-control {
        position: relative;
        flex: 0 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: baseline;
        font-size: 16px;
        input, textarea {
            flex: 1 1 auto;
            height: 100%;
            outline: none;
            border: 1px solid #CCC;
            border-radius: 0;
            padding: 11px 6px 10px 6px;
            /* FIXME need to more naturally make same height as search box */
            min-height: 44.5px;
            max-height: 8rem;
            overflow-y: auto;
        }
        > *:focus {
            outline: none;
            border: var(--focus-border);
            box-shadow: var(--focus-box-shadow);
        }
        input::placeholder, textarea::placeholder {
            font-style: italic;
            opacity: 50%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
            border: none !important;
        }
        button {
            border: none;
            height: 46px;
        }
    }
}
.chat-box-control.disabled, .chat-box-control textarea:disabled {
    background-color: var(--disabled-bg-color);
}

.search-box .autosuggest {
    border-radius: 0;
    flex: auto;
    max-width: 800px;
}

.search-box .select__control {
    border: 1px solid #ccc;
    border-radius: var(--default-border-radius);
    font-size: 16px;
    /* hack to align control with dropdown */
    margin-right: -2px;
}

.search-box {
    .select__control--is-focused, .select__control:focus {
        border: var(--focus-border);
        /*border-color: #007eff;*/
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 0 3px rgba(0, 126, 255, 0.1);
    }
}

.search-box .select__control--is-focused.select__control--menu-is-open {
    border-color: #ccc;
    box-shadow: none;
}

.search-box .select__placeholder {
    font-style: italic;
    opacity: 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
}

.search-box .select__loading-indicator {
    display: none;
}


.autosuggest .loading-animation {
    margin: 0;
    padding-top: 8px;
}

.autosuggest .select__control,
.autosuggest .select__value-container,
.autosuggest .select__multi-value {
    height: 100%;
}

.autosuggest .select__value-container {
    flex-wrap: nowrap;
    padding: 4px;
}

.autosuggest .select__input-container {
    display: inline-flex;
    flex-direction: row;
}

.autosuggest .select__input {
    flex: auto;
}

.autosuggest .select__input-container:after {
    flex: 0 0 auto;
    content: none;
    display: none;
    max-width: 2px;
}

.autosuggest .select__menu {
    /* Hack to align dropdown with control above */
    margin: 0 0 0 1px;
    border-start-start-radius: 0;
    border-start-end-radius: 0;
    overflow: hidden;
    z-index: 2;
}

.autosuggest.autosuggest-error .select__menu-notice {
    color: red;
}

.autosuggest .select__menu-list:has(.loading-animation) {
    overflow: hidden;
    height: 2.75rem;
}

.autosuggest .select__option{
    padding: 0 8px;
}

.autosuggest .autosuggest-value {
    margin: 9px 0 0 9px;
}

.select__outer {
    max-height: 300px;
}

.autosuggest .select__menu-list {
    max-height: 400px;
    word-break: break-word;
}

.autosuggest .select__multi-value {
    display: flex;
    max-width: 400px;
    min-width: initial;
    border: 1px solid #CCCCCC;
    background-color: #EBF5FF;
    flex-direction: row-reverse;
    height: 32px;
    font-size: 18px;
    margin: 0 4px;
}

.autosuggest .select__multi-value__label {
    border-left:  1px solid #CCC;
    padding: 4px;
    background-color: #ebf5ff ;
    color: #515151;
    overflow-x: hidden;
    margin: 0;
    max-width: 100%;
    text-overflow: ellipsis;
    text-align: center;
}


.autosuggest .select__multi-value__remove.focused,
.autosuggest .select__multi-value__remove:hover {
    color: #515151;
    background-color: rgba(0,113,230,.08);
}

.autosuggest .select__multi-value__label:hover {
    cursor: pointer;
    color: #515151;
    background-color: rgba(0,113,230,.08);
}

.autosuggest .select__multi-value__input-x {
    line-height: 1.3;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1 1;
    max-height: 2.6rem;
}

.autosuggest .select__arrow-zone {
    width: 40px;
}

.autosuggest .select__multi-value__label.resolving-terms-token {
    display: inline-flex;
    flex-direction: row;
    max-height: 2em;
    border-left: none;
}

.autosuggest .resolving-placeholder {
    display: flex;
    flex-direction: row;
}

.autosuggest .resolving-placeholder .placeholder-text {
    margin: 0 4px;
}

.autosuggest .select__multi-value__label .spinner {
    margin: 4px;
    width: 15px;
    height: 15px;
    color: black;
    opacity: 50%;
}

.autosuggest {
    .select__arrow, .select__clear-indicator, .select__multi-value__remove.resolving-terms-token {
        display: none;
    }
}

.search-box button.search-btn {
    border: none;
    background: none;
    font-size: 19px;
    margin: auto;
}

.search-box button, .chat-session .chat-box-control button {
    font-size: 19px;
    border: 1px solid #cccccc;
    background-color: #fff;
    width: 46px;
    min-width: 46px;
    max-height: 46px;
}

.chat-session .chat-box-control button {
    border: none;
}

.landing-page .search-box button.chat, .search-box button.chat:has(.hal9000) {
    border: none;
    overflow: hidden;
    background: transparent;
    img.hal9000 {
        max-height: 46px;
    }
}

.search-box button img:not(.hal9000) {
  width: 32px;
  height: 32px;
}

.search-box button.mol-editor-dlg-btn {
  padding: 0;
  margin-left: 2px;
}

.search-btn{
    color: #4285F4;
    border: none;
    background: linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
    width: 34px;
    height: 34px;
    font-size: 1.1em;
    padding: 6px;
}

.search-btn:hover:not([disabled]) {
    font-weight: bold;
}

.search-btn[disabled], .search-btn:disabled, .search-btn.disabled {
    /* Use opacity to avoid setting up different "disabled" colors */
    opacity: var(--disabled-opacity);
    pointer-events: none;
}

.search-btn:focus {
    outline:0;
}

.search-opt{
    margin: 10px;
}

.search-opt i {
    color: #2D668B;
}

.search-opt .search-btm-ln {
    height: 1px;
    background-color: #cecece;
}

.search-opt p {
    font-size: 0.8em;
}

.search-box .autosuggest-value{
    margin-bottom: 5px !important;
}

.delete-search-term,
.search-item-close{
    float: right;
    line-height: 1.0;
    top: 5px;
    right: 5px;
    color: #FFF;
    position: absolute;
    font-size: 15px;
    text-align: center;
    border: 1px solid #FFF0F0;
    border-radius: 16px;
    width: 16px;
    background-color: #DABEBE;
}

.delete-search-term:hover,
.search-item-close:hover{
    font-weight: bold;
    cursor: pointer;
    background-color: #FF6363;
}

.search-box-textarea-placeholder{
    color: #aaa;
    line-height: 34px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    top: 4px;
    left: 98px;
}

.autosuggest .select__group {
    padding: 4px 0 0 0;
}

.autosuggest .select__group-heading {
    color: #b0b0b0 !important;
    font-size: 14px;
    font-weight: bold;
    margin: auto;
}

.as-option button {
    width: 100%;
    padding: 0;
    margin: auto;
    text-align: right;
    background: none;
    border: none;
    font-size: 16px;
    font-style: italic;
}

.token-details-body {
    position: relative;
    width: 100%;
    word-break: break-word;
}

.token-details {
    overflow: hidden;
}

.token-details .compound-structure-image {
    float: left;
    width: 128px;
    height: 128px;
    margin-right: 5px;
}

.token-details .compound-structure-image:after {
    clear: both;
}

@keyframes spinner {
    0% { transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}

.spinner {
    display: inline-block;
    border: solid gray;
    border-top: solid black;
    border-radius: 50%;
    animation: spinner 0.5s linear infinite;
}

