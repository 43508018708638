/* Theme file originally from bootstrap 3, updated for bootstrap 5 */
:root {
    /* TODO: convert to rem units */
    --icon-size: 36px;
    --icon-font-size: 24px;
    --button-size: 47px;
    --small-button-size: var(--icon-size);
    --border-width: 1px;
    --focus-border-width: 1px;
    --margin: 8px;
    --padding: 4px;
    --gap: 4px;
    --line-height: 1.42857143;
    /* Make loader size relative to the surrounding text */
    --loader-size: 1.2em;
    --loader-timing: 1s;

    --default-border-radius: 2px;
    --default-color: #212529;
    --disabled-color: #A8A8A8;
    /* default-color + disabled-opacity should be approximately the same as disabled-color */
    --disabled-opacity: 40%;
    --body-color: var(--default-color);
    --bold-color: #000;
    --active-color: #4285F4;
    --active-bg-color: #e0e0e0;
    --highlight-color: #eef5ff;
    --link-color: var(--active-color);
    --link-bold-color: #0759e4;
    /*--link-color: #6572b5;*/ /* was 3e51b5 */
    /*--link-bold-color: #344495;*/
    --link-hover-color: var(--link-bold-color);
    --button-border-color: #c7c7c7;
    --button-border-radius: var(--default-border-radius);
    --bg-color: #fefefe;
    --hover-bg-color: #e9ecef;
    --focus-color: #212529;
    --focus-bg-color: var(--hover-bg-color);
    --focus-border-color: var(--active-color);
    --focus-border: var(--focus-border-width) solid var(--focus-border-color);
    --focus-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
    --nofocus-border: var(--focus-border-width) solid transparent;
    --marked-bg-color: none;
    --disabled-bg-color: #EEE;

    --dropdown-color: #737373;

    --plex-yellow: #f7be33;
    --plex-orange: #e4762f;
    --plex-red: #e54028;
    --plex-black: #000;
}

body {
    line-height: var(--line-height);
}

b, strong {
    color: var(--bold-color);
}

mark {
    display: inline;
    background-color: var(--marked-bg-color);
}

.navbar.app-header {
    border-bottom: 1px solid #e7e7e7;
}

.h4, h4 {
    font-size: 18px;
}

button, .btn {
    color: #737373;
    padding: 0;
    border-color: var(--button-border-color);
    border-radius: var(--button-border-radius);
}

button.glyphicon, .btn.glyphicon {
    /*padding: 4px;*/
    position: relative;
}
span.glyphicon, div.glyphicon {
    text-align: center;
    margin: auto;
}
button.glyphicon::before, .btn.glyphicon::before {
}

/* Don't apply opacity to container elements, since its effect is cumulative */
button:disabled, .disabled, .disabled :not(:empty), .disabled input, .disabled select, select.disabled {
    cursor: default;
    color: var(--disabled-color);
}

.btn-danger,
.btn-default,
.btn-info,
.btn-primary,
.btn-success,
.btn-warning {
    color: #000000;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.navbar-menus, .grid-filter, .navbar-searchbox, .landing-page {
    .dropdown-toggle:after {
        display: none;
    }
}
.btn-danger.active,
.btn-danger:active,
.btn-default.active,
.btn-default:active,
.btn-info.active,
.btn-info:active,
.btn-primary.active,
.btn-primary:active,
.btn-success.active,
.btn-success:active,
.btn-warning.active,
.btn-warning:active {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-danger.disabled,
.btn-danger:disabled,
.btn-danger[disabled],
.btn-default.disabled,
.btn-default:disabled,
.btn-default[disabled],
.btn-info.disabled,
.btn-info:disabled,
.btn-info[disabled],
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary[disabled],
.btn-success.disabled,
.btn-success:disabled,
.btn-success[disabled],
.btn-warning.disabled,
.btn-warning:disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-warning {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0);
    border-color: inherit;
    color: var(--disabled-color);
}

.btn-danger .badge,
.btn-default .badge,
.btn-info .badge,
.btn-primary .badge,
.btn-success .badge,
.btn-warning .badge {
    text-shadow: none;
}
.btn.active,
.btn:active {
    background-image: none;
}
.btn-primary,
.btn-default {
    text-shadow: 0 1px 0 #fff;
    background-image: -webkit-linear-gradient(top, #fff 0, #e0e0e0 100%);
    background-image: -o-linear-gradient(top, #fff 0, #e0e0e0 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#e0e0e0));
    background-image: linear-gradient(to bottom, #fff 0, #e0e0e0 100%);
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe0e0e0', GradientType=0);*/
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    /*border-color: #dbdbdb;*/
    border-color: #ccc;
    background-color: #e0e0e0;
}

.btn:hover,
.btn:focus,
.btn-primary:focus,
.btn-primary:hover,
.btn-default:focus,
.btn-default:hover{
    background-color: var(--hover-bg-color);
    background-position: 0 -15px;
    box-shadow: none;
    color: inherit;
}
.btn.active,
.btn:active,
.btn-primary.active,
.btn-primary:active,
.btn-default.active,
.btn-default:active {
    background-color: var(--active-bg-color);
    border-color: #dbdbdb;
}

.btn.disabled,
.btn.disabled.active,
.btn.disabled.focus,
.btn.disabled:active,
.btn.disabled:focus,
.btn.disabled:hover,
.btn[disabled],
.btn[disabled].active,
.btn[disabled].focus,
.btn[disabled]:active,
.btn[disabled]:focus,
.btn[disabled]:hover,
.btn.disabled,
.btn-primary.disabled.active,
.btn-primary.disabled.focus,
.btn-primary.disabled:active,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled],
.btn-primary[disabled].active,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary.active,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover,
.btn-default.disabled,
.btn-default.disabled.active,
.btn-default.disabled.focus,
.btn-default.disabled:active,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled],
.btn-default[disabled].active,
.btn-default[disabled].focus,
.btn-default[disabled]:active,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default.active,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
    /*background-color: #e0e0e0;*/
    background-image: none;
    opacity: var(--disabled-color);
    cursor: pointer;
}
/*
.btn-primary {
    background-image: -webkit-linear-gradient(top, #337ab7 0, #265a88 100%);
    background-image: -o-linear-gradient(top, #337ab7 0, #265a88 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#265a88));
    background-image: linear-gradient(to bottom, #337ab7 0, #265a88 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-color: #245580;
}
.btn-primary:focus,
.btn-primary:hover {
    background-color: #265a88;
    background-position: 0 -15px;
}

.btn-primary.active,
.btn-primary:active {
    background-color: #e0e0e0;
    border-color: #dbdbdb;
}
.btn-primary.disabled,
.btn-primary.disabled.active,
.btn-primary.disabled.focus,
.btn-primary.disabled:active,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled],
.btn-primary[disabled].active,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary.active,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
    background-color: #265a88;
    background-image: none;
}
*/

.modal-header .btn-close {
    font-size: 8px;
    background-size: 8px;
}
.modal-footer .btn {
    font-size: 14px;
}

.btn-success {
    background-image: -webkit-linear-gradient(top, #5cb85c 0, #419641 100%);
    background-image: -o-linear-gradient(top, #5cb85c 0, #419641 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5cb85c), to(#419641));
    background-image: linear-gradient(to bottom, #5cb85c 0, #419641 100%);
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff419641', GradientType=0);*/
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-color: #3e8f3e;
}
.btn-success:focus,
.btn-success:hover {
    background-color: #419641;
    background-position: 0 -15px;
}
.btn-success.active,
.btn-success:active {
    background-color: #419641;
    border-color: #3e8f3e;
}
.btn-success.disabled,
.btn-success.disabled.active,
.btn-success.disabled.focus,
.btn-success.disabled:active,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success[disabled],
.btn-success[disabled].active,
.btn-success[disabled].focus,
.btn-success[disabled]:active,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success.active,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:hover {
    background-color: #419641;
    background-image: none;
}
.btn-info {
    background-image: -webkit-linear-gradient(top, #5bc0de 0, #2aabd2 100%);
    background-image: -o-linear-gradient(top, #5bc0de 0, #2aabd2 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5bc0de), to(#2aabd2));
    background-image: linear-gradient(to bottom, #5bc0de 0, #2aabd2 100%);
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2aabd2', GradientType=0);*/
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-color: #28a4c9;
}
.btn-info:focus,
.btn-info:hover {
    background-color: #2aabd2;
    background-position: 0 -15px;
}
.btn-info.active,
.btn-info:active {
    background-color: #2aabd2;
    border-color: #28a4c9;
}
.btn-info.disabled,
.btn-info.disabled.active,
.btn-info.disabled.focus,
.btn-info.disabled:active,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info[disabled],
.btn-info[disabled].active,
.btn-info[disabled].focus,
.btn-info[disabled]:active,
.btn-info[disabled]:focus,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info.active,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:hover {
    background-color: #2aabd2;
    background-image: none;
}
.btn-warning {
    background-image: -webkit-linear-gradient(top, #f0ad4e 0, #eb9316 100%);
    background-image: -o-linear-gradient(top, #f0ad4e 0, #eb9316 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f0ad4e), to(#eb9316));
    background-image: linear-gradient(to bottom, #f0ad4e 0, #eb9316 100%);
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0ad4e', endColorstr='#ffeb9316', GradientType=0);*/
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-color: #e38d13;
}
.btn-warning:focus,
.btn-warning:hover {
    background-color: #eb9316;
    background-position: 0 -15px;
}
.btn-warning.active,
.btn-warning:active {
    background-color: #eb9316;
    border-color: #e38d13;
}
.btn-warning.disabled,
.btn-warning.disabled.active,
.btn-warning.disabled.focus,
.btn-warning.disabled:active,
.btn-warning.disabled:focus,
.btn-warning.disabled:hover,
.btn-warning[disabled],
.btn-warning[disabled].active,
.btn-warning[disabled].focus,
.btn-warning[disabled]:active,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning.active,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:hover {
    background-color: #eb9316;
    background-image: none;
}
.btn-danger {
    background-image: -webkit-linear-gradient(top, #d9534f 0, #c12e2a 100%);
    background-image: -o-linear-gradient(top, #d9534f 0, #c12e2a 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#d9534f), to(#c12e2a));
    background-image: linear-gradient(to bottom, #d9534f 0, #c12e2a 100%);
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffc12e2a', GradientType=0);*/
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-color: #b92c28;
}
.btn-danger:focus,
.btn-danger:hover {
    background-color: #c12e2a;
    background-position: 0 -15px;
}
.btn-danger.active,
.btn-danger:active {
    background-color: #c12e2a;
    border-color: #b92c28;
}
.btn-danger.disabled,
.btn-danger.disabled.active,
.btn-danger.disabled.focus,
.btn-danger.disabled:active,
.btn-danger.disabled:focus,
.btn-danger.disabled:hover,
.btn-danger[disabled],
.btn-danger[disabled].active,
.btn-danger[disabled].focus,
.btn-danger[disabled]:active,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger.active,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:hover {
    background-color: #c12e2a;
    background-image: none;
}
.img-thumbnail,
.thumbnail {
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}
.dropdown-menu {
    top: 100%;
    padding: 5px 0;
    offset: 2px 0;
    font-size: 14px;
    box-shadow: 0 6px 12px rgb(0, 0, 0, 0.18);
    border-radius: var(--button-border-radius);
}

legend {
    font-size: 1rem;
}

.dropdown-item {
    margin: auto;
    max-width: 300px;
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    display: flex;
    line-height: 0.95rem;
    clear: both;
    font-size: 1rem;
    font-weight: 400;
    color: #333;
    white-space: nowrap;
    text-decoration: none;
    padding: 0.25rem 0.75rem;
    > span {
        flex: 0 1;
    }
    select {
        color: inherit;
        background: inherit;
    }
    label:has(select:disabled) {
        color: var(--disabled-color);
    }
}

.dropdown-item:active {
    label:has(select) {
        color: var(--active-color);
    }
    label:has(select:disabled) {
        color: var(--disabled-color);
    }
    .ctm__checkbox, .numeric {
        label {
            color: var(--active-color);
        }
    }
    .ctm__checkbox:disabled, .numeric:disabled {
        label {
            color: var(--disabled-color);
        }
    }
}

.dropdown-menu .dropdown-item :first-child:not(img):not(.fi) {
    flex: 1 1;
}

.dropdown-group {
    box-sizing: content-box;
}

.dropdown-group > * {
  text-indent: 1rem;
}

.dropdown-header {
    padding: 3px 20px;
    font-size: 1rem;
    white-space: nowrap;
}

.dropdown-header:disabled, .dropdown-header.disabled {
    color: var(--disabled-color);
}

.dropdown-header.compound-group-header {
    padding: 3px 20px;
    font-size: inherit;
    font-weight: 400;
    line-height: 1.42857143;
    /*color: #333;*/
}

.dropdown-divider {
  height: 0.1rem;
  margin: 0.25rem 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.navbar-nav.navbar-menus .dropdown-menu {
    left: auto;
    right: 0;
    position: absolute;
}

.search-btn .search-icon {
    margin-top: -12px;
    margin-right: 3px;
}
.search-link .search-icon {
    margin-right: 3px;
}

.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item.active {
    background: none;
}


.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item.active:hover  {
    background-color: #e8e8e8;
    background-image: -webkit-linear-gradient(top, #f5f5f5 0, #e8e8e8 100%);
    background-image: -o-linear-gradient(top, #f5f5f5 0, #e8e8e8 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f5f5f5), to(#e8e8e8));
    background-image: linear-gradient(to bottom, #f5f5f5 0, #e8e8e8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
    background-repeat: repeat-x;
}
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item.active:hover {
  background-image: linear-gradient(180deg,#f5f5f5 0,#e8e8e8);
}

/*
.dropdown-menu .dropdown-item.active,
.dropdown-menu .dropdown-item.active:focus,
.dropdown-menu .dropdown-item.active:hover {
    background-color: #2e6da4;
    background-image: -webkit-linear-gradient(top, #337ab7 0, #2e6da4 100%);
    background-image: -o-linear-gradient(top, #337ab7 0, #2e6da4 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#2e6da4));
    background-image: linear-gradient(to bottom, #337ab7 0, #2e6da4 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
    background-repeat: repeat-x;
}
*/
.navbar-default {
    background-image: -webkit-linear-gradient(top, #fff 0, #f8f8f8 100%);
    background-image: -o-linear-gradient(top, #fff 0, #f8f8f8 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f8f8f8));
    background-image: linear-gradient(to bottom, #fff 0, #f8f8f8 100%);
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff8f8f8', GradientType=0);*/
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-radius: var(--button-border-radius);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.075);
}
.navbar-default .navbar-nav .nav-link.active,
.navbar-default .navbar-nav .show .nav-link {
    background-image: -webkit-linear-gradient(top, #dbdbdb 0, #e2e2e2 100%);
    background-image: -o-linear-gradient(top, #dbdbdb 0, #e2e2e2 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#dbdbdb), to(#e2e2e2));
    background-image: linear-gradient(to bottom, #dbdbdb 0, #e2e2e2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdbdbdb', endColorstr='#ffe2e2e2', GradientType=0);
    background-repeat: repeat-x;
    -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.075);
}
.navbar-brand,
.navbar-nav .nav-link {
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
}
.navbar-brand {
    padding: 0;
}
.navbar-inverse {
    background-image: -webkit-linear-gradient(top, #3c3c3c 0, #222 100%);
    background-image: -o-linear-gradient(top, #3c3c3c 0, #222 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#3c3c3c), to(#222));
    background-image: linear-gradient(to bottom, #3c3c3c 0, #222 100%);
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3c3c3c', endColorstr='#ff222222', GradientType=0);*/
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-radius: var(--button-border-radius);
}
.navbar-inverse .navbar-nav .nav-link.active,
.navbar-inverse .navbar-nav .show .nav-link {
    background-image: -webkit-linear-gradient(top, #080808 0, #0f0f0f 100%);
    background-image: -o-linear-gradient(top, #080808 0, #0f0f0f 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#080808), to(#0f0f0f));
    background-image: linear-gradient(to bottom, #080808 0, #0f0f0f 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff080808', endColorstr='#ff0f0f0f', GradientType=0);
    background-repeat: repeat-x;
    -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
}
.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-nav .nav-link {
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.navbar-fixed-bottom,
.navbar-fixed-top,
.navbar-static-top {
    border-radius: 0;
}
/* Why different color/bg settings for different width? */
/*@media (max-width: 767px) {
    .navbar .navbar-nav .show .dropdown-menu .dropdown-item,
    .navbar .navbar-nav .show .dropdown-menu .dropdown-item:focus,
    .navbar .navbar-nav .show .dropdown-menu .dropdown-item:hover {
        color: #fff;
        background-image: -webkit-linear-gradient(top, #337ab7 0, #2e6da4 100%);
        background-image: -o-linear-gradient(top, #337ab7 0, #2e6da4 100%);
        background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#2e6da4));
        background-image: linear-gradient(to bottom, #337ab7 0, #2e6da4 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
        background-repeat: repeat-x;
    }
}*/
.alert {
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.alert-success {
    background-image: -webkit-linear-gradient(top, #dff0d8 0, #c8e5bc 100%);
    background-image: -o-linear-gradient(top, #dff0d8 0, #c8e5bc 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#dff0d8), to(#c8e5bc));
    background-image: linear-gradient(to bottom, #dff0d8 0, #c8e5bc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdff0d8', endColorstr='#ffc8e5bc', GradientType=0);
    background-repeat: repeat-x;
    border-color: #b2dba1;
}
.alert-info {
    background-image: -webkit-linear-gradient(top, #d9edf7 0, #b9def0 100%);
    background-image: -o-linear-gradient(top, #d9edf7 0, #b9def0 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#d9edf7), to(#b9def0));
    background-image: linear-gradient(to bottom, #d9edf7 0, #b9def0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffb9def0', GradientType=0);
    background-repeat: repeat-x;
    border-color: #9acfea;
}
.alert-warning {
    background-image: -webkit-linear-gradient(top, #fcf8e3 0, #f8efc0 100%);
    background-image: -o-linear-gradient(top, #fcf8e3 0, #f8efc0 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fcf8e3), to(#f8efc0));
    background-image: linear-gradient(to bottom, #fcf8e3 0, #f8efc0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffcf8e3', endColorstr='#fff8efc0', GradientType=0);
    background-repeat: repeat-x;
    border-color: #f5e79e;
}
.alert-error, .alert-danger {
    background-image: -webkit-linear-gradient(top, #f2dede 0, #e7c3c3 100%);
    background-image: -o-linear-gradient(top, #f2dede 0, #e7c3c3 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f2dede), to(#e7c3c3));
    background-image: linear-gradient(to bottom, #f2dede 0, #e7c3c3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffe7c3c3', GradientType=0);
    background-repeat: repeat-x;
    border-color: #dca7a7;
}
.progress {
    background-image: -webkit-linear-gradient(top, #ebebeb 0, #f5f5f5 100%);
    background-image: -o-linear-gradient(top, #ebebeb 0, #f5f5f5 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ebebeb), to(#f5f5f5));
    background-image: linear-gradient(to bottom, #ebebeb 0, #f5f5f5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffebebeb', endColorstr='#fff5f5f5', GradientType=0);
    background-repeat: repeat-x;
}
.progress-bar {
    background-image: -webkit-linear-gradient(top, #337ab7 0, #286090 100%);
    background-image: -o-linear-gradient(top, #337ab7 0, #286090 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#286090));
    background-image: linear-gradient(to bottom, #337ab7 0, #286090 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff286090', GradientType=0);
    background-repeat: repeat-x;
}
.progress-bar-success {
    background-image: -webkit-linear-gradient(top, #5cb85c 0, #449d44 100%);
    background-image: -o-linear-gradient(top, #5cb85c 0, #449d44 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5cb85c), to(#449d44));
    background-image: linear-gradient(to bottom, #5cb85c 0, #449d44 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff449d44', GradientType=0);
    background-repeat: repeat-x;
}
.progress-bar-info {
    background-image: -webkit-linear-gradient(top, #5bc0de 0, #31b0d5 100%);
    background-image: -o-linear-gradient(top, #5bc0de 0, #31b0d5 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5bc0de), to(#31b0d5));
    background-image: linear-gradient(to bottom, #5bc0de 0, #31b0d5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff31b0d5', GradientType=0);
    background-repeat: repeat-x;
}
.progress-bar-warning {
    background-image: -webkit-linear-gradient(top, #f0ad4e 0, #ec971f 100%);
    background-image: -o-linear-gradient(top, #f0ad4e 0, #ec971f 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f0ad4e), to(#ec971f));
    background-image: linear-gradient(to bottom, #f0ad4e 0, #ec971f 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0ad4e', endColorstr='#ffec971f', GradientType=0);
    background-repeat: repeat-x;
}
.progress-bar-danger {
    background-image: -webkit-linear-gradient(top, #d9534f 0, #c9302c 100%);
    background-image: -o-linear-gradient(top, #d9534f 0, #c9302c 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#d9534f), to(#c9302c));
    background-image: linear-gradient(to bottom, #d9534f 0, #c9302c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffc9302c', GradientType=0);
    background-repeat: repeat-x;
}
.progress-bar-striped {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.list-group {
    border-radius: var(--button-border-radius);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}
.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
    text-shadow: 0 -1px 0 #286090;
    background-image: -webkit-linear-gradient(top, #337ab7 0, #2b669a 100%);
    background-image: -o-linear-gradient(top, #337ab7 0, #2b669a 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#2b669a));
    background-image: linear-gradient(to bottom, #337ab7 0, #2b669a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2b669a', GradientType=0);
    background-repeat: repeat-x;
    border-color: #2b669a;
}
.list-group-item.active .badge,
.list-group-item.active:focus .badge,
.list-group-item.active:hover .badge {
    text-shadow: none;
}
.panel {
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.panel-default .panel-heading {
    background-image: -webkit-linear-gradient(top, #f5f5f5 0, #e8e8e8 100%);
    background-image: -o-linear-gradient(top, #f5f5f5 0, #e8e8e8 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f5f5f5), to(#e8e8e8));
    background-image: linear-gradient(to bottom, #f5f5f5 0, #e8e8e8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
    background-repeat: repeat-x;
}
.panel-primary .panel-heading {
    background-image: -webkit-linear-gradient(top, #337ab7 0, #2e6da4 100%);
    background-image: -o-linear-gradient(top, #337ab7 0, #2e6da4 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#2e6da4));
    background-image: linear-gradient(to bottom, #337ab7 0, #2e6da4 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
    background-repeat: repeat-x;
}
.panel-success .panel-heading {
    background-image: -webkit-linear-gradient(top, #dff0d8 0, #d0e9c6 100%);
    background-image: -o-linear-gradient(top, #dff0d8 0, #d0e9c6 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#dff0d8), to(#d0e9c6));
    background-image: linear-gradient(to bottom, #dff0d8 0, #d0e9c6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdff0d8', endColorstr='#ffd0e9c6', GradientType=0);
    background-repeat: repeat-x;
}
.panel-info > .panel-heading {
    background-image: -webkit-linear-gradient(top, #d9edf7 0, #c4e3f3 100%);
    background-image: -o-linear-gradient(top, #d9edf7 0, #c4e3f3 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#d9edf7), to(#c4e3f3));
    background-image: linear-gradient(to bottom, #d9edf7 0, #c4e3f3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffc4e3f3', GradientType=0);
    background-repeat: repeat-x;
}
.panel-warning > .panel-heading {
    background-image: -webkit-linear-gradient(top, #fcf8e3 0, #faf2cc 100%);
    background-image: -o-linear-gradient(top, #fcf8e3 0, #faf2cc 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fcf8e3), to(#faf2cc));
    background-image: linear-gradient(to bottom, #fcf8e3 0, #faf2cc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffcf8e3', endColorstr='#fffaf2cc', GradientType=0);
    background-repeat: repeat-x;
}
.panel-danger > .panel-heading {
    background-image: -webkit-linear-gradient(top, #f2dede 0, #ebcccc 100%);
    background-image: -o-linear-gradient(top, #f2dede 0, #ebcccc 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f2dede), to(#ebcccc));
    background-image: linear-gradient(to bottom, #f2dede 0, #ebcccc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffebcccc', GradientType=0);
    background-repeat: repeat-x;
}
.well {
    background-image: -webkit-linear-gradient(top, #e8e8e8 0, #f5f5f5 100%);
    background-image: -o-linear-gradient(top, #e8e8e8 0, #f5f5f5 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e8e8e8), to(#f5f5f5));
    background-image: linear-gradient(to bottom, #e8e8e8 0, #f5f5f5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe8e8e8', endColorstr='#fff5f5f5', GradientType=0);
    background-repeat: repeat-x;
    border-color: #dcdcdc;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    background-color: #ccc;
}

/* glyphicon definitions dropped from bootstrap */
@font-face {
    font-family: 'Glyphicons Halflings';
    src: url(../fonts/glyphicons-halflings-regular.eot);
    src: url(../fonts/glyphicons-halflings-regular.eot?#iefix) format('embedded-opentype'), url(../fonts/glyphicons-halflings-regular.woff2) format('woff2'), url(../fonts/glyphicons-halflings-regular.woff) format('woff'), url(../fonts/glyphicons-halflings-regular.ttf) format('truetype'), url(../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular) format('svg')
}

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: transparent;
}

.glyphicon-wireless:before {
    filter: grayscale(100%);
    content: "\01F6DC";
}

.glyphicon-asterisk:before {
    content: "\002a"
}

.glyphicon-plus:before {
    content: "\002b"
}

.glyphicon-eur:before, .glyphicon-euro:before {
    content: "\20ac"
}

.glyphicon-minus:before {
    content: "\2212"
}

.glyphicon-cloud:before {
    content: "\2601"
}

.glyphicon-envelope:before {
    content: "\2709"
}

.glyphicon-pencil:before {
    content: "\270f"
}

.glyphicon-glass:before {
    content: "\e001"
}

.glyphicon-music:before {
    content: "\e002"
}

.glyphicon-search:before {
    content: "\e003"
}

.glyphicon-heart:before {
    content: "\e005"
}

.glyphicon-star:before {
    content: "\e006"
}

.glyphicon-star-empty:before {
    content: "\e007"
}

.glyphicon-user:before {
    content: "\e008"
}

.glyphicon-film:before {
    content: "\e009"
}

.glyphicon-th-large:before {
    content: "\e010"
}

.glyphicon-th:before {
    content: "\e011"
}

.glyphicon-th-list:before {
    content: "\e012"
}

.glyphicon-ok:before {
    content: "\e013"
}

.glyphicon-remove:before {
    content: "\e014"
}

.glyphicon-zoom-in:before {
    content: "\e015"
}

.glyphicon-zoom-out:before {
    content: "\e016"
}

.glyphicon-off:before {
    content: "\e017"
}

.glyphicon-signal:before {
    content: "\e018"
}

.glyphicon-cog:before {
    content: "\e019"
}

.glyphicon-trash:before {
    content: "\e020"
}

.glyphicon-home:before {
    content: "\e021"
}

.glyphicon-file:before {
    content: "\e022"
}

.glyphicon-time:before {
    content: "\e023"
}

.glyphicon-road:before {
    content: "\e024"
}

.glyphicon-download-alt:before {
    content: "\e025"
}

.glyphicon-download:before {
    content: "\e026"
}

.glyphicon-upload:before {
    content: "\e027"
}

.glyphicon-inbox:before {
    content: "\e028"
}

.glyphicon-play-circle:before {
    content: "\e029"
}

.glyphicon-repeat:before {
    content: "\e030"
}

.glyphicon-refresh:before {
    content: "\e031"
}

.glyphicon-list-alt:before {
    content: "\e032"
}

.glyphicon-lock:before {
    content: "\e033"
}

.glyphicon-flag:before {
    content: "\e034"
}

.glyphicon-headphones:before {
    content: "\e035"
}

.glyphicon-volume-off:before {
    content: "\e036"
}

.glyphicon-volume-down:before {
    content: "\e037"
}

.glyphicon-volume-up:before {
    content: "\e038"
}

.glyphicon-qrcode:before {
    content: "\e039"
}

.glyphicon-barcode:before {
    content: "\e040"
}

.glyphicon-tag:before {
    content: "\e041"
}

.glyphicon-tags:before {
    content: "\e042"
}

.glyphicon-book:before {
    content: "\e043"
}

.glyphicon-bookmark:before {
    content: "\e044"
}

.glyphicon-print:before {
    content: "\e045"
}

.glyphicon-camera:before {
    content: "\e046"
}

.glyphicon-font:before {
    content: "\e047"
}

.glyphicon-bold:before {
    content: "\e048"
}

.glyphicon-italic:before {
    content: "\e049"
}

.glyphicon-text-height:before {
    content: "\e050"
}

.glyphicon-text-width:before {
    content: "\e051"
}

.glyphicon-align-left:before {
    content: "\e052"
}

.glyphicon-align-center:before {
    content: "\e053"
}

.glyphicon-align-right:before {
    content: "\e054"
}

.glyphicon-align-justify:before {
    content: "\e055"
}

.glyphicon-list:before {
    content: "\e056"
}

.glyphicon-indent-left:before {
    content: "\e057"
}

.glyphicon-indent-right:before {
    content: "\e058"
}

.glyphicon-facetime-video:before {
    content: "\e059"
}

.glyphicon-picture:before {
    content: "\e060"
}

.glyphicon-map-marker:before {
    content: "\e062"
}

.glyphicon-adjust:before {
    content: "\e063"
}

.glyphicon-tint:before {
    content: "\e064"
}

.glyphicon-edit:before {
    content: "\e065"
}

.glyphicon-share:before {
    content: "\e066"
}

.glyphicon-check:before {
    content: "\e067"
}

.glyphicon-move:before {
    content: "\e068"
}

.glyphicon-step-backward:before {
    content: "\e069"
}

.glyphicon-fast-backward:before {
    content: "\e070"
}

.glyphicon-backward:before {
    content: "\e071"
}

.glyphicon-play:before {
    content: "\e072"
}

.glyphicon-pause:before {
    content: "\e073"
}

.glyphicon-stop:before {
    content: "\e074"
}

.glyphicon-forward:before {
    content: "\e075"
}

.glyphicon-fast-forward:before {
    content: "\e076"
}

.glyphicon-step-forward:before {
    content: "\e077"
}

.glyphicon-eject:before {
    content: "\e078"
}

.glyphicon-chevron-left:before {
    content: "\e079"
}

.glyphicon-chevron-right:before {
    content: "\e080"
}

.glyphicon-plus-sign:before {
    content: "\e081"
}

.glyphicon-minus-sign:before {
    content: "\e082"
}

.glyphicon-remove-sign:before {
    content: "\e083"
}

.glyphicon-ok-sign:before {
    content: "\e084"
}

.glyphicon-question-sign:before {
    content: "\e085"
}

.glyphicon-info-sign:before {
    content: "\e086"
}

.glyphicon-screenshot:before {
    content: "\e087"
}

.glyphicon-remove-circle:before {
    content: "\e088"
}

.glyphicon-ok-circle:before {
    content: "\e089"
}

.glyphicon-ban-circle:before {
    content: "\e090"
}

.glyphicon-arrow-left:before {
    content: "\e091"
}

.glyphicon-arrow-right:before {
    content: "\e092"
}

.glyphicon-arrow-up:before {
    content: "\e093"
}

.glyphicon-arrow-down:before {
    content: "\e094"
}

.glyphicon-share-alt:before {
    content: "\e095"
}

.glyphicon-resize-full:before {
    content: "\e096"
}

.glyphicon-resize-small:before {
    content: "\e097"
}

.glyphicon-exclamation-sign:before {
    content: "\e101"
}

.glyphicon-gift:before {
    content: "\e102"
}

.glyphicon-leaf:before {
    content: "\e103"
}

.glyphicon-fire:before {
    content: "\e104"
}

.glyphicon-eye-open:before {
    content: "\e105"
}

.glyphicon-eye-close:before {
    content: "\e106"
}

.glyphicon-warning-sign:before {
    content: "\e107"
}

.glyphicon-plane:before {
    content: "\e108"
}

.glyphicon-calendar:before {
    content: "\e109"
}

.glyphicon-random:before {
    content: "\e110"
}

.glyphicon-comment:before {
    content: "\e111"
}

.glyphicon-magnet:before {
    content: "\e112"
}

.glyphicon-chevron-up:before {
    content: "\e113"
}

.glyphicon-chevron-down:before {
    content: "\e114"
}

.glyphicon-retweet:before {
    content: "\e115"
}

.glyphicon-shopping-cart:before {
    content: "\e116"
}

.glyphicon-folder-close:before {
    content: "\e117"
}

.glyphicon-folder-open:before {
    content: "\e118"
}

.glyphicon-resize-vertical:before {
    content: "\e119"
}

.glyphicon-resize-horizontal:before {
    content: "\e120"
}

.glyphicon-hdd:before {
    content: "\e121"
}

.glyphicon-bullhorn:before {
    content: "\e122"
}

.glyphicon-bell:before {
    content: "\e123"
}

.glyphicon-certificate:before {
    content: "\e124"
}

.glyphicon-thumbs-up:before {
    content: "\e125"
}

.glyphicon-thumbs-down:before {
    content: "\e126"
}

.glyphicon-hand-right:before {
    content: "\e127"
}

.glyphicon-hand-left:before {
    content: "\e128"
}

.glyphicon-hand-up:before {
    content: "\e129"
}

.glyphicon-hand-down:before {
    content: "\e130"
}

.glyphicon-circle-arrow-right:before {
    content: "\e131"
}

.glyphicon-circle-arrow-left:before {
    content: "\e132"
}

.glyphicon-circle-arrow-up:before {
    content: "\e133"
}

.glyphicon-circle-arrow-down:before {
    content: "\e134"
}

.glyphicon-globe:before {
    content: "\e135"
}

.glyphicon-wrench:before {
    content: "\e136"
}

.glyphicon-tasks:before {
    content: "\e137"
}

.glyphicon-filter:before {
    content: "\e138"
}

.glyphicon-briefcase:before {
    content: "\e139"
}

.glyphicon-fullscreen:before {
    content: "\e140"
}

.glyphicon-dashboard:before {
    content: "\e141"
}

.glyphicon-paperclip:before {
    content: "\e142"
}

.glyphicon-heart-empty:before {
    content: "\e143"
}

.glyphicon-link:before {
    content: "\e144"
}

.glyphicon-phone:before {
    content: "\e145"
}

.glyphicon-pushpin:before {
    content: "\e146"
}

.glyphicon-usd:before {
    content: "\e148"
}

.glyphicon-gbp:before {
    content: "\e149"
}

.glyphicon-sort:before {
    content: "\e150"
}

.glyphicon-sort-by-alphabet:before {
    content: "\e151"
}

.glyphicon-sort-by-alphabet-alt:before {
    content: "\e152"
}

.glyphicon-sort-by-order:before {
    content: "\e153"
}

.glyphicon-sort-by-order-alt:before {
    content: "\e154"
}

.glyphicon-sort-by-attributes:before {
    content: "\e155"
}

.glyphicon-sort-by-attributes-alt:before {
    content: "\e156"
}

.glyphicon-unchecked:before {
    content: "\e157"
}

.glyphicon-expand:before {
    content: "\e158"
}

.glyphicon-collapse-down:before {
    content: "\e159"
}

.glyphicon-collapse-up:before {
    content: "\e160"
}

.glyphicon-log-in:before {
    content: "\e161"
}

.glyphicon-flash:before {
    content: "\e162"
}

.glyphicon-log-out:before {
    content: "\e163"
}

.glyphicon-new-window:before {
    content: "\e164"
}

.glyphicon-record:before {
    content: "\e165"
}

.glyphicon-save:before {
    content: "\e166"
}

.glyphicon-open:before {
    content: "\e167"
}

.glyphicon-saved:before {
    content: "\e168"
}

.glyphicon-import:before {
    content: "\e169"
}

.glyphicon-export:before {
    content: "\e170"
}

.glyphicon-send:before {
    content: "\e171"
}

.glyphicon-floppy-disk:before {
    content: "\e172"
}

.glyphicon-floppy-saved:before {
    content: "\e173"
}

.glyphicon-floppy-remove:before {
    content: "\e174"
}

.glyphicon-floppy-save:before {
    content: "\e175"
}

.glyphicon-floppy-open:before {
    content: "\e176"
}

.glyphicon-credit-card:before {
    content: "\e177"
}

.glyphicon-transfer:before {
    content: "\e178"
}

.glyphicon-cutlery:before {
    content: "\e179"
}

.glyphicon-header:before {
    content: "\e180"
}

.glyphicon-compressed:before {
    content: "\e181"
}

.glyphicon-earphone:before {
    content: "\e182"
}

.glyphicon-phone-alt:before {
    content: "\e183"
}

.glyphicon-tower:before {
    content: "\e184"
}

.glyphicon-stats:before {
    content: "\e185"
}

.glyphicon-sd-video:before {
    content: "\e186"
}

.glyphicon-hd-video:before {
    content: "\e187"
}

.glyphicon-subtitles:before {
    content: "\e188"
}

.glyphicon-sound-stereo:before {
    content: "\e189"
}

.glyphicon-sound-dolby:before {
    content: "\e190"
}

.glyphicon-sound-5-1:before {
    content: "\e191"
}

.glyphicon-sound-6-1:before {
    content: "\e192"
}

.glyphicon-sound-7-1:before {
    content: "\e193"
}

.glyphicon-copyright-mark:before {
    content: "\e194"
}

.glyphicon-registration-mark:before {
    content: "\e195"
}

.glyphicon-cloud-download:before {
    content: "\e197"
}

.glyphicon-cloud-upload:before {
    content: "\e198"
}

.glyphicon-tree-conifer:before {
    content: "\e199"
}

.glyphicon-tree-deciduous:before {
    content: "\e200"
}

.glyphicon-cd:before {
    content: "\e201"
}

.glyphicon-save-file:before {
    content: "\e202"
}

.glyphicon-open-file:before {
    content: "\e203"
}

.glyphicon-level-up:before {
    content: "\e204"
}

.glyphicon-copy:before {
    content: "\e205"
}

.glyphicon-paste:before {
    content: "\e206"
}

.glyphicon-alert:before {
    content: "\e209"
}

.glyphicon-equalizer:before {
    content: "\e210"
}

.glyphicon-king:before {
    content: "\e211"
}

.glyphicon-queen:before {
    content: "\e212"
}

.glyphicon-pawn:before {
    content: "\e213"
}

.glyphicon-bishop:before {
    content: "\e214"
}

.glyphicon-knight:before {
    content: "\e215"
}

.glyphicon-baby-formula:before {
    content: "\e216"
}

.glyphicon-tent:before {
    content: "\26fa"
}

.glyphicon-blackboard:before {
    content: "\e218"
}

.glyphicon-bed:before {
    content: "\e219"
}

.glyphicon-apple:before {
    content: "\f8ff"
}

.glyphicon-erase:before {
    content: "\e221"
}

.glyphicon-hourglass:before {
    content: "\231b"
}

.glyphicon-lamp:before {
    content: "\e223"
}

.glyphicon-duplicate:before {
    content: "\e224"
}

.glyphicon-piggy-bank:before {
    content: "\e225"
}

.glyphicon-scissors:before {
    content: "\e226"
}

.glyphicon-bitcoin:before {
    content: "\e227"
}

.glyphicon-btc:before {
    content: "\e227"
}

.glyphicon-xbt:before {
    content: "\e227"
}

.glyphicon-yen:before {
    content: "\00a5"
}

.glyphicon-jpy:before {
    content: "\00a5"
}

.glyphicon-ruble:before {
    content: "\20bd"
}

.glyphicon-rub:before {
    content: "\20bd"
}

.glyphicon-scale:before {
    content: "\e230"
}

.glyphicon-ice-lolly:before {
    content: "\e231"
}

.glyphicon-ice-lolly-tasted:before {
    content: "\e232"
}

.glyphicon-education:before {
    content: "\e233"
}

.glyphicon-option-horizontal:before {
    content: "\e234"
}

.glyphicon-option-vertical:before {
    content: "\e235"
}

.glyphicon-menu-hamburger:before {
    content: "\e236"
}

.glyphicon-modal-window:before {
    content: "\e237"
}

.glyphicon-oil:before {
    content: "\e238"
}

.glyphicon-grain:before {
    content: "\e239"
}

.glyphicon-sunglasses:before {
    content: "\e240"
}

.glyphicon-text-size:before {
    content: "\e241"
}

.glyphicon-text-color:before {
    content: "\e242"
}

.glyphicon-text-background:before {
    content: "\e243"
}

.glyphicon-object-align-top:before {
    content: "\e244"
}

.glyphicon-object-align-bottom:before {
    content: "\e245"
}

.glyphicon-object-align-horizontal:before {
    content: "\e246"
}

.glyphicon-object-align-left:before {
    content: "\e247"
}

.glyphicon-object-align-vertical:before {
    content: "\e248"
}

.glyphicon-object-align-right:before {
    content: "\e249"
}

.glyphicon-triangle-right:before {
    content: "\e250"
}

.glyphicon-triangle-left:before {
    content: "\e251"
}

.glyphicon-triangle-bottom:before {
    content: "\e252"
}

.glyphicon-triangle-top:before {
    content: "\e253"
}

.glyphicon-console:before {
    content: "\e254"
}

.glyphicon-superscript:before {
    content: "\e255"
}

.glyphicon-subscript:before {
    content: "\e256"
}

.glyphicon-menu-left:before {
    content: "\e257"
}

.glyphicon-menu-right:before {
    content: "\e258"
}

.glyphicon-menu-down:before {
    content: "\e259"
}

.glyphicon-menu-up:before {
    content: "\e260"
}

.close {
    opacity: 0.5;
    cursor: pointer;
}

.close:hover, .close:focus {
    opacity: 1;
}

.carousel-control-next .glyphicon, .carousel-control-prev .glyphicon {
    color: gray;
    font-size: 3em;
    text-decoration: none;
}

.btn.dropdown-toggle, .show > .btn.dropdown-toggle {
    color: #737373;
    background-color: #fff;
    background-image: none;
    border: 1px solid #cccccc;
    border-radius: var(--button-border-radius);
    padding: 9px 13px 7px;
}

:focus-visible {
    border: var(--focus-border);
}

a:not(:focus-visible) {
    border: var(--nofocus-border);
}

.plex-yellow {
    background-color: var(--plex-yellow);
}
.plex-orange {
    background-color: var(--plex-orange);
}
.plex-red {
    background-color: var(--plex-red);
}
.plex-black {
    background-color: var(--plex-black);
}

.radio-group {
    text-indent: 1rem;
}

.dropdown-menu .radio-group div {
    text-indent: 0;
}

.dropdown-group {
    margin-left: 1rem;
    .radio-group {
        text-indent: 1rem;
        div {
            margin-left: 1rem;
        }
    }
}

button[class^="fi-rs-"],
button[class*=" fi-rs-"],
div[class^="fi-rs-"],
div[class*="fi-rs-"] {
    padding: 8px 8px 0 8px;
    width: var(--icon-size);
    font-size: var(--icon-font-size);
}

button[class^="fi-rs-"]:before,
button[class*=" fi-rs-"]:before,
div[class^="fi-rs-"]:before,
div[class*="fi-rs-"]:before {
    font-family: uicons-regular-straight !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.select__menu {
    width: fit-content;
    overflow: hidden;
}

.link {
    a:not(:hover) {
        text-decoration: none;
    }
    border: none;
    color: var(--link-color);
    background: none;
    margin: var(--margin) 0;
}

.link:hover {
    color: var(--link-hover-color);
    text-decoration: underline;
}
